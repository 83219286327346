import axios from "axios";
import { API_URL } from "@/const";
import { saveAs } from "file-saver";

export default {
  data() {
    return {
      selectGeneratorCollectList: [], //入力テキストで絞る排出事業者選択肢
      selectGeneratorDisposalList: [], //入力テキストで絞る排出事業者選択肢
      shareInfoList: [],
      inputMemberList: [],
      memberList: [],
      generatorCollectList: [],
      generatorDisposalList: [],
    };
  },
  methods: {
    // 検索一覧取得API
    getShareInfoSearchApi(searchParam, isPaging) {
      this.errMsgs = [];
      // 検索が押下された場合
      if (!isPaging) {
        this.searchedParam = JSON.parse(JSON.stringify(searchParam)); // deepcopy
        this.paginationParams.page = 1; // 1ページ目に初期化
      }

      // 検索条件をクエリ情報として設定
      const searchParams = new URLSearchParams();
      if (searchParam.subject)
        searchParams.append("subject", searchParam.subject);
      if (searchParam.memberId)
        searchParams.append("memberId", searchParam.memberId);
      searchParam.collectCompanyId.forEach((ccid) => {
        if (ccid) searchParams.append("collectCompanyList", ccid);
      });
      searchParam.disposalCompanyId.forEach((dcid) => {
        if (dcid) searchParams.append("disposalCompanyList", dcid);
      });

      if (searchParam.createDateFrom)
        searchParams.append("createDateFrom", searchParam.createDateFrom);
      if (searchParam.createDateTo)
        searchParams.append("createDateTo", searchParam.createDateTo);
      if (searchParam.nextUpdateDateFrom)
        searchParams.append(
          "nextUpdateDateFrom",
          searchParam.nextUpdateDateFrom
        );
      if (searchParam.nextUpdateDateTo)
        searchParams.append("nextUpdateDateTo", searchParam.nextUpdateDateTo);
      if (searchParam.isSetFile)
        searchParams.append("isSetFile", searchParam.isSetFile);

      searchParams.append("page", this.paginationParams.page);
      searchParams.append("pageSize", this.paginationParams.pageSize);
      searchParams.append(
        "sort",
        `${this.sortParams.column}:${this.sortParams.type}`
      );

      return axios
        .get(API_URL.SHAREINFO.SEARCH + searchParams.toString())
        .then((res) => {
          this.shareInfoList = res.data.List;
          this.paginationParams.total = res.data.searchParams.pagination.total;
          this.$store.commit("searchParamShareinfo/set", this.searchedParam);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 共有情報登録API
    inputShareInfoApi(postData) {
      axios
      .post(API_URL.SHAREINFO.CREATE, postData)
      .then((res) => {
        // 正常に更新が完了した場合、詳細画面に遷移する
        this.processing = false;
        this.isConfirmModalShow = false;
        this.$router.push("/generate/share/info/" + res.data.shareInfoId);
      })
      .catch((err) => {
        this.errMsgs.push(
          err.response && err.response.data
            ? err.response.data.message
            : err.message
        );
        this.processing = false;
        this.isConfirmModalShow = false;
        window.scrollTo({ top: 0, behavior: "smooth" });
      });
    },
    // 詳細・編集画面で呼び出すAPI
    getShareInfoDetailApi(shareinfo_id, isEdit) {
      return axios
        .get(API_URL.SHAREINFO.DETAIL + shareinfo_id)
        .then((res) => {
          // 取得データ格納
          this.shareInfoList = res.data;
          // 編集画面遷移時は登録用フォームに取得データを反映する
          if (isEdit) {
            this.form.title = this.shareInfoList.title;
            this.form.content = this.shareInfoList.content;
            this.form.memberId = this.shareInfoList.memberInfo.id;
            if(this.shareInfoList.collectCompanyInfo) {
              this.form.collectCompanyId = this.shareInfoList.collectCompanyInfo.id;
            }
            if(this.shareInfoList.disposalCompanyInfo) {
              this.form.disposalCompanyId = this.shareInfoList.disposalCompanyInfo.id;
            }
            this.form.serverLink = this.shareInfoList.serverLink;
            this.form.shareInfoFileList = this.shareInfoList.shareInfoFileList;
            this.form.nextUpdateDate = this.shareInfoList.nextUpdateDate;
          } else {
            // 詳細画面遷移時はID情報を配列情報に追加する
            this.shareInfoList.id = shareinfo_id;
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 404) {
              this.$router.push("/error/404");
              return;
            }
          }
          console.log(err);
        });
    },
    // 共有情報編集API
    editShareInfoApi(shareinfo_id, postData) {
      return axios
        .put(API_URL.SHAREINFO.EDIT + shareinfo_id, postData)
        .then(() => {
          // 正常に更新が完了した場合、詳細画面に遷移する
          this.processing = false;
          this.isConfirmModalShow = false;
          this.$router.push("/generate/share/info/" + shareinfo_id);
        })
        .catch((err) => {
          this.errMsgs.push(
            err.response && err.response.data
              ? err.response.data.message
              : err.message
          );
          this.processing = false;
          this.isConfirmModalShow = false;
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    // 共有情報削除API
    deleteShareInfoApi(shareinfo_id, isDetail) {
      const isActive = false; //削除APIなので
      const URL = API_URL.SHAREINFO.DELETE + shareinfo_id;
      return axios
        .patch(URL, {isActive})
        .then(() => {
          this.errMsgs = [];
          if (isDetail) {
            // 詳細画面: 正常に削除が完了した場合、一覧画面に遷移する
            this.$router.push("/generate/share/info");
          } else {
            // 一覧画面: 正常に削除が完了した場合、一覧を更新する
            this.getShareInfoSearchApi(this.searchedParam, false);
          }
        })
        .catch((err) => {
          this.errMsgs = [];
          this.errMsgs.push(
            err.response && err.response.data
              ? err.response.data.message
              : err.message
          );
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    // 運搬事業者選択肢取得API
    getGeneratorCollectApi() {
      this.getContractCollectCompanySelection().then((data) => {
        this.generatorCollectList = data
        this.selectGeneratorCollectList = data
        while (this.selectedGeneratorCollect.length < this.searchParam.collectCompanyId.length) {
          this.selectedGeneratorCollect.push(null);
        }
        this.searchParam.collectCompanyId.forEach( (c, index) => {
          this.selectedGeneratorCollect[index] = c ? this.generatorCollectList.find(e => e.id === c) : null
        })
        this.isLoadingSearchGeneratorCollect = false
      }).catch(() => {})
    },

    // 処分事業者選択肢取得API
    getGeneratorDisposalApi() {
      this.getContractDisposalCompanySelection().then((data) => {
        this.generatorDisposalList = data;
        this.selectGeneratorDisposalList = data;
        while (this.selectedGeneratorDisposal.length < this.searchParam.disposalCompanyId.length) {
          this.selectedGeneratorDisposal.push(null);
        }
        this.searchParam.disposalCompanyId.forEach( (d, index) => {
          this.selectedGeneratorDisposal[index] = d ? this.generatorDisposalList.find(e => e.id === d) : null
        })
        this.isLoadingSearchGeneratorDisposal = false
      }).catch(() => {})
    },
    // 登録・編集画面時に取得する担当者リスト情報
    getShareInfoInputMemberListApi() {
      return axios
      .get(API_URL.CONTRACT.INPUT_MEMBER)
      .then((res) => {
        this.inputMemberList = res.data.memberList;
      })
      .catch(() => {});
    },
    // 検索欄に表示する担当者リスト情報
    getShareInfoMemberListApi() {
      return axios
      .get(API_URL.CONTRACT.MEMBER)
      .then((res) => {
        this.memberList = res.data.memberList;
      })
      .catch(() => {});
    },
    // ファイルアップロードAPI
    uploadApi(file) {
      const params = new FormData();
      params.append("file", file);
      params.append("fileName", file.name);
      return axios
        .post(API_URL.COMMON.FILE_UPLOAD, params, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          return res.data.filePath;
        });
    },
    downloadFileApi(shareinfo_id, fileName) {
      return axios
        .get(API_URL.SHAREINFO.DOWNLOAD + shareinfo_id, {
          responseType: "blob",
        })
        .then((res) => {
          const blob = new Blob([res.data], {
            type: res.data.type,
          });

          saveAs(blob, fileName);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
