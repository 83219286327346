export default {
  data() {
    return {
      validate: {
        title: { isValid: true, errMsg: "" },
        content: { isValid: true, errMsg: "" },
        collectCompanyId: { isValid: true, errMsg: "" },
        disposalCompanyId: { isValid: true, errMsg: "" },
        memberId: { isValid: true, errMsg: "" },
        serverLink: { isValid: true, errMsg: "" },
        nextUpdateDate: { isValid: true, errMsg: "" },
      },
      // 登録・編集フォーム用
      form: {
        // 件名
        title: "",
        // 内容
        content: "",
        // 運搬事業者
        collectCompanyId: null,
        // 処分事業者
        disposalCompanyId: null,
        // 担当者
        memberId: null,
        // 社内パス
        serverLink: "",
        // アップロードファイル
        shareInfoFileList: [],
        nextUpdateDate: "",
      },
    };
  },
  methods: {
    runValidate() {
      let result = true;
      if (!this.validate.title.isValid) {
        this.validate.title.errMsg = "正しい値を入力してください。";
        result = false;
      }

      if (!this.validate.content.isValid) {
        this.validate.content.errMsg = "正しい値を入力してください。";
        result = false;
      }

      if (!this.validate.memberId.isValid) {
        this.validate.memberId.errMsg = "正しい値を入力してください。";
        result = false;
      }

      return result;
    },
  },
};
